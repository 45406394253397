import domReady from '@roots/sage/client/dom-ready';

/**
 * Application entrypoint
 */
domReady(async () => {
  
  /* ========================================
  Popup Video
  ===================================== */
  
  jQuery('.video-popup').magnificPopup({
      type: 'iframe'
      // other options
  });
  
  /* ========================================
  Change View of Post Grid
  ===================================== */
  
  jQuery('#grid-swap').on("click", function () {
    jQuery(this).toggleClass("grid-view");
    jQuery(".grid-list").addClass("opacity-0");
    setTimeout(function () {
      jQuery(".grid-list").toggleClass("grid-view");
      jQuery(".grid-list").removeClass("opacity-0");
    }, 401);
  });
  
  /* ========================================
  FacetWP Toggle Categories Show/Hide
  ===================================== */
  
  /* Show/Hide section on mobile */
  
  jQuery(".filter-title").each(function() {
    var currtext = jQuery(this).text();
    jQuery(this).html(currtext + '<span class="colon">:</span><span class="show-hide-icon"></span>');
    currtext = "";
  });
  
  jQuery(".filter-title").on("click", function() {
      if(window.outerWidth < 768) {
          if(jQuery(this).hasClass("showing-facets")) {
              jQuery(this).next().slideUp();
              jQuery(this).removeClass("showing-facets");
          }
          else {
              jQuery(this).next().slideDown();
              jQuery(this).addClass("showing-facets");
          }
      }
  });
  
  jQuery(".facetwp-facet-categories").find(".facetwp-expand").on("click", function() {
    console.log("Test");
    jQuery(this).toggleClass("hiding-children");
  });
  
  /* ========================================
  Accordion Toggle
  ===================================== */

  jQuery(".press-accordion-tab").on("click", function() {
    
      if (jQuery(this).hasClass('open')) {
          jQuery(this).removeClass('open');
      }
      else {
          jQuery(".press-accordion-tab.open").removeClass("open");
          jQuery(this).addClass("open");
      }
      
  });
  
  /* ========================================
  Tabs
  ===================================== */

  jQuery(".tab-button").on("click", function() {
    
    var tabnum = jQuery(this).attr("tabnum"); // Get tab number
    jQuery(this).parent().parent().find(".desktop-content").each(function() { jQuery(this).addClass("hidden") }); // Hide all tab content divs
    jQuery(this).parent().parent().find(".desktop-content[tabcontent=" + tabnum + "]").removeClass("hidden"); // Show the tab content that corresponds to the tab button pressed
    
    jQuery(".tab-button").removeClass("active");
    jQuery(this).addClass("active");
      
  });
  
  /* ========================================
  Tabs Mobile
  ===================================== */

  jQuery(".arrow-tab-btn").on("click", function() {
    
      if (jQuery(this).parent().hasClass('open')) {
          jQuery(this).parent().removeClass('open');
      }
      else {
          jQuery(".tab-button.open").removeClass("open");
          jQuery(this).parent().addClass("open");
      }
      
  });
  
  /* ========================================
  Like/Dislike Toggle
  ===================================== */

  jQuery(".like").on("click", function() {
    
      if (jQuery(".like-form").hasClass('open')) {
          jQuery(".like-form").removeClass('open');
          jQuery(".like").removeClass('open');
      }
      else if (jQuery(".dislike-form").hasClass('open')) {
          jQuery(".dislike-form").removeClass('open');
          jQuery(".dislike").removeClass('open');
          jQuery(".like-form").addClass("open");
          jQuery(".like").addClass("open");
      }
      else {
          jQuery(".like-form.open").removeClass("open");
          jQuery(".like-form").addClass("open");
          jQuery(".like").addClass("open");
      }
      
  });
  
  jQuery(".dislike").on("click", function() {
    
      if (jQuery(".dislike-form").hasClass('open')) {
          jQuery(".dislike-form").removeClass('open');
          jQuery(".dislike").removeClass('open');
      }
      else if (jQuery(".like-form").hasClass('open')) {
          jQuery(".like-form").removeClass('open');
          jQuery(".like").removeClass('open');
          jQuery(".dislike-form").addClass("open");
          jQuery(".dislike").addClass("open");
      }
      else {
          jQuery(".dislike-form.open").removeClass("open");
          jQuery(".dislike-form").addClass("open");
          jQuery(".dislike").addClass("open");
      }
      
  });
  
  /* ========================================
  Main Menu Navigation
  ===================================== */
  
  jQuery(".accordion-menu-container .menu-item-has-children > a").on("click", function(e) {
    e.preventDefault();
    if(!jQuery(this).parent().hasClass("open")) {
      jQuery(this).parent().addClass("open");
    }
    else {
      jQuery(this).parent().removeClass("open");
    }
    jQuery(this).next().slideToggle();
  });
  
  jQuery("#hamburger-button").on("click", function() {
    jQuery("body").toggleClass("overflow-hidden");
    jQuery("#main-navigation").toggleClass("hidden");
    setTimeout(function () {
      jQuery("#main-navigation .dark-overlay").toggleClass("opacity-full");
      jQuery(".main-navigation-container").toggleClass("menu-show");
    }, 50);
    jQuery("#main-navigation").attr("aria-hidden", "false");
    jQuery("#main-navigation").attr("aria-expanded", "true");
  });
  
  jQuery("#close-menu-button, #main-navigation .dark-overlay").on("click", function() {
    closeMenu();
  });
  
  jQuery(document).keyup(function(e) {
     if (e.key === "Escape") { // escape key maps to keycode `27`
        closeMenu();
      }
  });
  
  function closeMenu() {
    if(jQuery(".main-navigation-container").hasClass("menu-show")) {
      jQuery("body").toggleClass("overflow-hidden");
      jQuery(".main-navigation-container").toggleClass("menu-show");
      jQuery("#main-navigation .dark-overlay").toggleClass("opacity-full");
      setTimeout(function () {
        jQuery("#main-navigation").toggleClass("hidden");
      }, 400);
      jQuery("#main-navigation").attr("aria-hidden", "true");
      jQuery("#main-navigation").attr("aria-expanded", "false");
    }
  }
    
});

  /* Apply Class on Show/Hide Individual Parent Categories */
  
  document.addEventListener('facetwp-loaded', function() {
  
    console.log(jQuery(".facetwp-facet-categories").find(".facetwp-counter").next());
  
  });

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
import.meta.webpackHot?.accept(console.error);